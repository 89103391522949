import React, { useRef, useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row'

const NumberPad = props => {
  return (
		[[1,2,3, ''],[4,5,6, ''],[7,8,9, ''],['C',0,'00', 'DEL']].map((row, idx) => (
				<Row key={idx} style={{paddingLeft: '15%', height: '10vh', lineHeight: '50px', marginBottom: '2px'}}>
					{
						row.map((b, index) => (
							<div key={index} style={
								{
									boxShadow: '0px 0px 4px #000000DB', 
									backgroundColor: `${index % 4 == 3 ? '#169EDD' : 'white'}`, 
									color: `${index % 4 == 3 ? 'white' : 'black'}`,
									margin: '2px', 
									borderRadius: '4px', 
                  height: '100%',
									width: '20%',
                  lineHeight: '10vh'
                }
							} onClick={props.handleKeyClick}>{b}</div>
						))
					}
				</Row>
			)
		)
  )
}

export default NumberPad
