import Container from 'react-bootstrap/Container'
import logo2 from '../images/logo2.jpeg'
import kioskTips from '../images/kiosk-tips.png'
import kioskCheesy2 from '../images/kiosk-cheesy2.png'
import tap from '../images/tap.png'
import SupportedWallets from '../components/SupportedWallets'

export const Splash = props => {
  return (
    <>
    <Container style={{height: '100vh'}} onClick={() => (props.storeId && props.storeId != 0) || props.handleClickSplash()}>
  <div style={{width: '100px', textAlign:'center', height: '50px', lineHeight: '50px', left: '5px', top: '5px', color: '#efefef', borderRadius: '4px', marginTop: '10px', position: 'absolute'}} onClick={props.handleAdminClick}>
    {''}
  </div>
    
      <center style={{position: 'fixed', top: 0, left: '50%', transform: 'translate(-50%,0)', marginTop: '5vh'}}>
        <img src={logo2} style={{height: '30vh'}}/>
        <div style={{ffontSize: 'min(6vh,6vw)', width: '100vw', fontWeight: '700', fontSize: '65px'}}>
          {
            props.storeId == 0 ? ( //special store id for tips-only, during event
              <>
                <div><img src={kioskTips} style={{width: 'min(93vw,69vh)'}} /></div>
              </>
            ) : (
              props.storeId ? (
                <div style={{marginTop: '25px'}}><img src={kioskCheesy2} style={{width: 'min(93vw,69vh)'}} /></div>
              ) : (
                <></>
              )
            )
          }
        </div>
      </center>

      {
        !props.storeId ? (
          <center style={{fontFamily: 'Comic Sans MS', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%,0)', fontSize: '6vw', color: 'rgb(19, 82, 244)', width: '55%'}}>
            <img src={tap} style={{width: '300px', marginTop: '50px'}} />
          </center>
        ) : (
          <></>
        )
      }

      <SupportedWallets handleClickSplash={props.handleClickSplash} />

    </Container>
    </>
  )
}

export default Splash
