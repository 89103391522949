import React, { useRef, useEffect, useState } from 'react'
import cool from '../images/cool.png'

const Canvas = props => {
  const canvasRef = useRef(null)
  const doges = [
    {x: Math.random()*1180, y: Math.random()*1180 - 200, vel: (Math.random()*2)-1},
    {x: Math.random()*1180, y: Math.random()*1180 - 200, vel: (Math.random()*2)-1},
    {x: Math.random()*1180, y: Math.random()*1180 - 200, vel: (Math.random()*2)-1},
    {x: Math.random()*1180, y: Math.random()*1180 - 200, vel: (Math.random()*2)-1},
    {x: Math.random()*1180, y: Math.random()*1180 - 200, vel: (Math.random()*2)-1},
    {x: Math.random()*1180, y: Math.random()*1180 - 200, vel: (Math.random()*2)-1},
    {x: Math.random()*1180, y: Math.random()*1180 - 200, vel: (Math.random()*2)-1},
    {x: Math.random()*1180, y: Math.random()*1180 - 200, vel: (Math.random()*2)-1},
    {x: Math.random()*1180, y: Math.random()*1180 - 200, vel: (Math.random()*2)-1},
    {x: Math.random()*1180, y: Math.random()*1180 - 200, vel: (Math.random()*2)-1},
    {x: Math.random()*1180, y: Math.random()*1180 - 200, vel: (Math.random()*2)-1},
    {x: Math.random()*1180, y: Math.random()*1180 - 200, vel: (Math.random()*2)-1},
    {x: Math.random()*1180, y: Math.random()*1180 - 200, vel: (Math.random()*2)-1},
    {x: Math.random()*1180, y: Math.random()*1180 - 200, vel: (Math.random()*2)-1},
    {x: Math.random()*1180, y: Math.random()*1180 - 200, vel: (Math.random()*2)-1},
  ]

  const draw = (ctx, frameCount) => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
    var imgCool = new Image()
    imgCool.src = cool
    for (var i = 0; i < doges.length; i++) {
      doges[i].x +=  doges[i].vel
      doges[i].y += 1
      doges[i].vel += ((Math.random()*2)-1)*0.1
    }
    for (var i = 0; i < doges.length; i++) {
      ctx.drawImage(imgCool, doges[i].x, doges[i].y, 50, 50)
    }
  }

  useEffect(() => {
    const canvas = canvasRef.current
    const context = canvas.getContext('2d')
    let frameCount = 0
    let animationFrameId
    //Our draw come here
    const render = () => {
      frameCount++
      draw(context, frameCount)
      animationFrameId = window.requestAnimationFrame(render)
    }
    render()
  }, [draw])

  return <canvas style={{position: 'fixed', top: 0, zIndex: '9999'}} ref={canvasRef} {...props}/>
}
export default Canvas
