import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import RightPanel from '../components/RightPanel'

const DataEntry = props => {
  return (
    <div className="App" style={{height: '100vh'}}>
    	<div>

    		<Row>
    			<Col>
    				<div style={{backgroundColor: 'black', height: '50px', color: 'white', lineHeight: '50px', textAlign: 'left', paddingLeft: '5px'}}>
              Store ID: {props.storeId ? props.storeId : 'Unset'}
    				</div>
    			</Col>
    		</Row>

    		<Row style={{columnGap: 0, height: '100vh'}}>
    			<Col sm={{ span: 5 }} style={{paddingRight: 0, heightt: '100vh'}}>
    				<div style={{height: '100%', backgroundColor: 'grey', padding: '15px'}}>
              <div style={{border: '1px solid', width: '33%', borderRadius: '4px', height: '50px', lineHeight: '50px', backgroundColor: 'white'}} onClick={()=>window.location='/'}>
                Back/Cancel
              </div>
              <div style={{border: (props.storeId ? '1px solid #cbcbcb' : ''), width: '33%', textAlign:'center', height: '50px', lineHeight: '50px', left: '5px', top: '5px', color: '#efefef', borderRadius: '4px', marginTop: '10px'}} onClick={props.handleAdminClick}>
                {props.storeId ? 'Admin' : ''}
              </div>
    				</div>
    			</Col>

    			<Col sm={{ span: 7 }} style={{paddingLeft: 0}}>
    				<div style={{height: '100%', backgroundColor: '#D1D1D0'}}>
              <RightPanel numerals={props.numerals} order={props.order} toggleEntry={props.toggleEntry} keypadToggle={props.keypadToggle} handleKeyClick={props.handleKeyClick} total={props.total} handleClickDoge={props.handleClickDoge} />
    				</div>
    			</Col>
    		</Row>

    	</div>
    </div>
  )
}

export default DataEntry
