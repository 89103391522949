import '../App.css'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import React from 'react';
import QRCode from 'qrcode';
import socketIO from "socket.io-client";
import icon from '../images/dogeIcon.png';
import AsyncStorage from '@react-native-async-storage/async-storage';
//import { HDKey } from 'ethereum-cryptography/hdkey';
import { Linking } from 'react-native';
import { Buffer } from 'buffer'; // Ensure Buffer is available
import bitcoin from 'bitcoinjs-lib';
// import { BIP32Factory } from 'bip32';
// import * as bitcoin from 'bitcoinjs-lib';
// const bitcoin = require('bitcoinjs-lib');
const bip39 = require('bip39');
//const bip32 = require('bip32');
const hdkeystuff = require('ethereum-cryptography/hdkey');
//const Mnemonic = require('bitcore-mnemonic');
const bitcore = require('bitcore-lib-doge');
const DOGECOIN_NETWORK = {
  messagePrefix: '\x19Dogecoin Signed Message:\n',
  bech32: 'doge',
  bip32: {
    public: 0x02facafd,
    private: 0x02fac398
  },
  pubKeyHash: 0x1e,
  scriptHash: 0x16,
  wif: 0x9e,
};
window.Buffer = Buffer; // Set Buffer globally if not already set

export class Admin extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
      payments: [],
      storeId: 'Unset',
      wallets: [],
      tipsPool: [],
      apiKey: '',
		};

    this.handleClickWallet = this.handleClickWallet.bind(this)
    this.handleClickCloseOutDoge = this.handleClickCloseOutDoge.bind(this);
    this.handleClickStoreId = this.handleClickStoreId.bind(this)
    this.handleClickKey = this.handleClickKey.bind(this)
    this.handleClickMode = this.handleClickMode.bind(this)
    this.handleClickMerchantId = this.handleClickMerchantId.bind(this)
    this.handleClickCurrency = this.handleClickCurrency.bind(this);
    this.handleClickTipsMode = this.handleClickTipsMode.bind(this);
	}

  componentDidMount() {
    const checkAndSetConfig = async () => {
      const storeId = await AsyncStorage.getItem('store-id')
      if (storeId) {
        this.setState({storeId})
        let paymentsUrl = `https://app.dogeexpress.io/payments.json?store_id=${storeId}`
        fetch(paymentsUrl)
          .then(res => res.json()).then(payments =>
            {
              this.setState({payments})
            }
          );
      }
      const keyPairs = [
        {storeKey: 'api-key', stateKey: 'apiKey'},
        {storeKey: 'posMode', stateKey: 'posMode'},
        {storeKey: 'merchantId', stateKey: 'merchantId'},
        // {storeKey: 'currency', stateKey: 'currency'},
        {storeKey: 'tipsMode', stateKey: 'tipsMode'},
      ]
      for (var i = 0; i < keyPairs.length; i++) {
        var keyPair = keyPairs[i]
        console.log(keyPair)
        const storeVal = await AsyncStorage.getItem(keyPair.storeKey)
        if (storeVal) {
          this.setState({[keyPair.stateKey]: storeVal})
        }
      }
    }
    checkAndSetConfig()
    
    let walletsUrl = 'https://app.dogeexpress.io/wallets.json';
    fetch(walletsUrl)
      .then(res => res.json()).then(wallets =>
        {
          console.log(wallets)
          this.setState({wallets})
        }
      );
  }

  handleClickWallet(wallet) {
    console.log('clicked wallet: ', wallet)
    if (this.state.tipsPool.includes(wallet)) {
      var tipsPool = [...this.state.tipsPool]
      var index = tipsPool.indexOf(wallet)
      if (index != -1) {
        tipsPool.splice(index, 1)
        this.setState({tipsPool})
      }
    } else {
      this.setState((state,props) => (
          {tipsPool: [...state.tipsPool, wallet]}
        )
      )
    }
  }

	handleClickCloseOutDoge() {
	    console.log('close out doge')
	    const formData = new FormData();
	    for (var i = 0; i < this.state.tipsPool.length; i++) {
		formData.append("wallets[]", this.state.tipsPool[i])
	    }
	    let settleUrl = `https://app.dogeexpress.io/settle?store_id=${this.state.storeId}`;
	    fetch(settleUrl, {
		method: "POST",
		body: formData
	    }
		 ).then(res => {
		     console.log(res);
		     //this.setState({paid: true});
		     
		 }
       );
	}

  handleClickStoreId() {
    const key = 'store-id'
    const value2 = window.prompt('Please enter unique store identifier')
    AsyncStorage.setItem(key,value2);
    this.setState({storeId: value2})
    let paymentsUrl = `https://app.dogeexpress.io/payments.json?store_id=${value2}`
    fetch(paymentsUrl)
      .then(res => res.json()).then(payments =>
        {
          console.log(payments)
          this.setState({payments})
        }
      );
  }

  handleClickKey() {
    const key = 'api-key';
    let value2 = window.prompt('Enter seed phrase, or type "new" and click OK to create new wallet. Enter a dot by itself to reset.', this.state.apiKey || '')
    if (value2 && value2.length > 0) {
	if (value2 === '.') {
            value2 = '';
      }
      AsyncStorage.setItem(key,value2);
      this.setState({apiKey: value2});
    }
      if (value2 === 'new') {
	  const mnemonic = bip39.generateMnemonic()
	  console.log(mnemonic)
	  const seedBytes = null;//bip39.mnemonicToSeedSync(mnemonic);
	  const seed = mnemonic.toString();
	  //console.log(seed)
	  let xpriv;
	  let xpub;
	  bip39.mnemonicToSeed(mnemonic).then(seedObj => {
	      console.log('generating seed');
	      const seedBuffer = new Uint8Array(seedObj.buffer);
	      console.log(seedBuffer);
	      const hdkey = hdkeystuff.HDKey.fromMasterSeed(seedObj);
	      console.log('created hdkey')
	      console.log(hdkey)
	      xpriv = hdkey.privateExtendedKey;
	      xpub = hdkey.publicExtendedKey;
	      console.log(xpriv)
	      console.log(xpub)
	      window.alert("This is your wallet recovery seed. It is CRITICAL that you store this securely. It will allow you to access your funds and to use DogeExpress without loss of funds. You will be prompted to ensure that you have accurately recorded this phrase before continuing with setup: \n[\n"+seed+"\n]");
	      let conf = window.prompt('Re-enter the seed phrase words, each separated by a single space to confirm you have it stored correctly.')
	      conf = seed;
	      while (seed != conf.trim()) {
		  window.alert('Something went wrong either when you created or re-entered your seed phrase. Please try again.');
		  conf = window.prompt('Re-enter the seed phrase words, each separated by a single space to confirm you have it stored correctly.');
	      }
	      if (seed == conf.trim()) {
		  AsyncStorage.setItem(key, xpriv);
		  this.setState({apiKey: xpriv});

		  const walletUrl = process.env.REACT_APP_ENV === 'development' ? 'http://localhost:8000/wallets.json' : `https://app.dogeexpress.io/wallets.json`;
		  const fullUrl = walletUrl;
		  fetch(fullUrl, {
		      method: "POST",
		      headers: {
			  'Content-Type': 'application/json', // Specify the content type as JSON
		      },
		      body: JSON.stringify({wallet: {owner_name: this.state.storeId, hd_public_key: xpub}}),
		  }).then(res => res.json()).then(wallet =>
		      {
			  window.alert(`Saved new wallet, xpub: ${wallet.hd_public_key}`);
		      }
		  );
	      }
	  })
      }
  }

  handleClickMode(e) {
    let posMode = e.target.name;
    const key = 'posMode'
    console.log(posMode)
    AsyncStorage.setItem(key, posMode)
    this.setState({posMode})
  }

  handleClickMerchantId() {
    const key = 'merchantId'
    const merchantId = window.prompt('Enter merchant Id', this.state.merchantId)
    console.log(merchantId)
    AsyncStorage.setItem(key, merchantId)
    this.setState({merchantId})
  }

  handleClickCurrency() {
    const key = 'currency'
    const currency = window.prompt('Enter currency', this.state.currency)
    AsyncStorage.setItem(key, currency)
    this.setState({currency})
  }

  handleClickTipsMode(e) {
    var choice = e.target.name;
    const tipsMode = choice === 'tipsOff' ? 'false' : 'true';
    const key = 'tipsMode';
    AsyncStorage.setItem(key, tipsMode)
    this.setState({tipsMode});
  }

  tips() {
    let list = this.state.payments.map((item) => (item.tip && item.received >= item.amount*0.98 ? item.tip : 0))
    return list
  }

  map(prop) {
    return this.state.payments.map((item) => (item[prop] ? item[prop] : 0))
  }

  sum(arry) {
    return arry.reduce((partialSum, a) => partialSum + a, 0)
  }

	render() {
		return (
				<div style={{height: '100vh', width: '100vw'}}>

					<Row style={{backgroundColor: 'black', columnGap: 0}}>
						<Col sm={{span: 12}}>
							<Row>
								<Col sm={{span: 2}}>
									<div style={{height: '50px', width: '100px', color: 'white', border: '1px solid', borderRadius: '4px', lineHeight: '50px', textAlign: 'center', margin: '5px'}} onClick={() => window.location = '/'}>
										Home
									</div>
								</Col>
								<Col sm={{span: 8}} />
								<Col sm={{span: 2}}>
								</Col>
							</Row>
						</Col>
					</Row>

					<Row style={{backgroundColor: 'navy', height: '100%', padding: '10px'}}>
            <Col sm={{span: 1}}/>
            <Col>
              <Row style={{backgroundColor: 'white', height: '50px', marginBottom: '20px', padding: '10px', display: 'flex', flexDirection: 'column'}} onClick={this.handleClickStoreId}>
                <Col sm={6}>
                  Settings
                </Col>
                <Col sm={8}>
                </Col>
              </Row>

              <Row style={{backgroundColor: 'white', height: '50px', marginBottom: '20px', padding: '10px', display: 'flex', flexDirection: 'column'}} onClick={this.handleClickStoreId}>
                <Col sm={4}>
                  Store Id: 
                </Col>
                <Col sm={8}>
                  <div style={{border: '1px solid gray', width: 'auto', textAlign: 'center'}}>{this.state.storeId}</div>
                </Col>
              </Row>
              <Row style={{backgroundColor: 'white', height: '50px', marginBottom: '20px', padding: '10px', display: 'flex', flexDirection: 'column'}} onClick={this.handleClickKey}>
                <Col sm={4}>
                  Wallet: 
                </Col>
                <Col sm={8}>
                  <div style={{border: '1px solid gray', width: 'auto', textAlign: 'center'}}>{this.state.apiKey ? `${this.state.apiKey.slice(0,4)}` : 'Unset'}</div>
                </Col>
              </Row>
              <Row style={{backgroundColor: 'white', height: '50px', marginBottom: '20px', padding: '10px', display: 'flex', flexDirection: 'column'}} >
                <Col sm={4}>
                  POS: 
                </Col>
                <Col sm={8}>
                  <div style={{border: '1px solid gray', width: '100%', textAlign: 'center', display: 'flex', padding: '5px', justifyContent: 'center'}}>
                    <label>NA <input type="radio" value="Default" checked={this.state.posMode !== 'toast' && this.state.posMode !== 'square'} name="standalone" onChange={this.handleClickMode} /></label>
                    <div style={{width: '10px'}}>|</div>
                    <label>Tst <input type="radio" value="Toast" checked={this.state.posMode === 'toast'} name="toast" onChange={this.handleClickMode} /></label>
                    <div style={{width: '10px'}}>|</div>
                    <label>Sqr <input type="radio" value="Square" checked={this.state.posMode === 'square'} name="square" onChange={this.handleClickMode} /></label>
                  </div>
                </Col>
              </Row>
              {
                (this.state.posMode !== 'toast' && this.state.posMode !== 'square') ? (<></>) : (
                  <Row style={{backgroundColor: 'white', height: '50px', marginBottom: '20px', padding: '10px', display: 'flex', flexDirection: 'column'}} onClick={this.handleClickMerchantId}>
                    <Col sm={4}>
                      {this.state.posMode} Id:
                    </Col>
                    <Col sm={8}>
                      <div style={{border: '1px solid gray', width: 'auto', textAlign: 'center'}}>{this.state.merchantId || 'Unset'}</div>
                    </Col>
                  </Row>
                )
              }
              <Row style={{backgroundColor: 'white', height: '50px', marginBottom: '20px', padding: '10px', display: 'flex', flexDirection: 'column'}} onClick={this.handleClickCurrency}>
                <Col sm={4}>
                  Currencies:
                </Col>
                <Col sm={8}>
                  <div style={{border: '1px solid gray', width: 'auto', textAlign: 'center'}}>DOGE</div>
                </Col>
              </Row>
              <Row style={{backgroundColor: 'white', height: '50px', marginBottom: '20px', padding: '10px', display: 'flex', flexDirection: 'column'}}>
                <Col sm={4}>
                  Tips: 
                </Col>
                <Col sm={8}>
                  <div style={{border: '1px solid gray', width: '100%', textAlign: 'center', display: 'flex', padding: '5px', justifyContent: 'center'}}>
                    <label>Off <input type="radio" value="Off" checked={this.state.tipsMode === 'false'} name="tipsOff" onChange={this.handleClickTipsMode} />
                    </label>
                    <div style={{width: '10px'}}>|</div>
                    <label>On <input type="radio" value="On" checked={this.state.tipsMode !== 'false'} name="tipsOn" onChange={this.handleClickTipsMode} />
                    </label>
                  </div>
                </Col>
              </Row>

              <Row style={{backgroundColor: 'white', padding: '10px'}}>
                <Col>

                  <div>Current Status</div>
                  <hr/>
                
                  <div>{this.state.payments.filter((item)=>(item.received >= item.amount*0.98)).length} payments</div>
                  {
                    // this.state.wallets.map((wallet) => (
                    //     <div style={{backgroundColor: this.state.tipsPool.includes(wallet.address) ? 'gray' : ''}} onClick={()=>this.handleClickWallet(wallet.address)}>{wallet.owner_name}: {wallet.address}</div>
                    //   )
                    // )
                  }
                </Col>
              </Row>

              <Row/>
            </Col>

            <Col sm={{span: 1}}/>

            <Col>
              <Row style={{backgroundColor: 'white', height: '475px'}}>
                <Col>
                  <Row>
                    <Col sm={{span: 8}}>Z Report</Col>
                    <Col sm={{span: 4}}>{new Date().toDateString()}</Col>
                  </Row>
                  <hr/>
                  <div>DOGE PAYOUT DETAILS</div>
                  <hr/>
                  <Row>
                    <Col>Total Doge Collected</Col>
                    <Col sm={{span: 3}} style={{display: 'flex', justifyContent: 'right'}}>Ɖ{(this.sum(this.map('received'))).toFixed(4)}</Col>
                  </Row>
                  {
                    this.state.tipsMode === 'false' ? (<></>) : (
                      <Row>
                        <Col>Tips Collected</Col>
                        <Col sm={{span: 3}} style={{display: 'flex', justifyContent: 'right'}}>Ɖ{(this.sum(this.tips())).toFixed(4)}</Col>
                      </Row>
                    )
                  }
                  <Row>
                    <Col>DogeExpress Service Fee (2%)</Col>
                    <Col sm={{span: 3}} style={{display: 'flex', justifyContent: 'right'}}>Ɖ{((this.sum(this.map('received')) - this.sum(this.tips())) * 0.02).toFixed(4)}</Col>
                  </Row>
                  <hr/>
                  <Row>
                    <Col>Retail Payout (less any transaction fees/tips)</Col>
                    <Col sm={{span: 3}} style={{display: 'flex', justifyContent: 'right'}}>Ɖ{((this.sum(this.map('received')) - this.sum(this.tips())) * (1 - 0.02)).toFixed(4)}</Col>
                  </Row>

        <br/>
                  <Row>
                    <Col sm={{span: 2}}/>
                    <Col sm={{span: 8}}><button onClick={this.handleClickCloseOutDoge}>Close Out DogeExpress Payments</button></Col>
                    <Col sm={{span: 2}}/>
                  </Row>
        <br/>
        {
          this.state.paid ? 
                  <Row>
                    <Col sm={{span: 5}}/>
                    <Col sm={{span: 2}} style={{border: '1px black solid', textAlign: 'center', backgroundColor: 'green', color: 'white'}}><div>Paid</div></Col>
                    <Col sm={{span: 5}}/>
                  </Row>
           : <div/>
        }

                </Col>
              </Row>
            </Col>
            <Col sm={{span: 1}}/>
					</Row>

        </div>
			);
	}
}

export default Admin;
