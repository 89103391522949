import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import icon from '../images/dogeIcon.png'
import NumberPad from '../components/NumberPad'

const RightPanel = props => {
  return (
    <Row>
    	<Col sm={{span: 2}} />

    	<Col>
    		<Row style={{height: '40px', lineHeight: '40px', marginTop: '10px'}}>
    			<div sm={{span: 5}} style={{width: '45%', fontSize: '14px'}}><strong>Balance due</strong></div>
    			<div sm={{span: 4}} style={{width: '50%', 
    				backgroundColor: (props.keypadToggle == 'balance' ? 'white' : '#a8a9a8'), 
    				borderRadius: '4px', 
            // height: '50px', lineHeight: '50px',
    				border: '1px solid', borderColor: (props.numerals.length == 0 || props.total() == 0 ? 'red' : 'black')}} onClick={() => props.toggleEntry('balance')}>
            ${props.numerals.length == 0 ? '0.00' : (props.numerals.join('')/100).toFixed(2)}
          </div>
        </Row>

    		<Row style={{height: '40px', lineHeight: '40px', marginTop: '10px'}}>
    			<div sm={{span: 5}} style={{width: '45%', fontSize: '14px'}}><strong>Order Id</strong></div>
    			<div sm={{span: 4}} style={{width: '50%', 
    				backgroundColor: (props.keypadToggle == 'order' ? 'white' : '#a8a9a8'), 
    				borderRadius: '4px', 
            // height: '50px', lineHeight: '50px',
    				border: '1px solid', borderColor: (props.order.length == 0 ? 'red' : 'black')}} onClick={() => props.toggleEntry('order')}>{props.order}
          </div>
    		</Row>

    		<Row style={{height: '10px'}} />

    		<Row style={{height: '10vh', margin: '5px'}}>
            <div style={{
    					backgroundColor: '#a8a9a8', 
    					borderRadius: '4px', 
    					border: '1px solid', marginTop: '1px', marginLeft: '5px', //height: '50px', 
    					paddingLeft: 0, paddingRight: 0, 
              // paddingTop: '15px',
    					fontSize: '14px', 
    					lineHeight: '10vh'}} sm={{span: 10}}
    					onClick={props.handleClickDoge}
    					>
    						{ <img src={icon} style={{width: '20px', height: '20px', marginRight: '5px'}} /> }
    						DogeExpress
    					</div>
        </Row>
    		{
          // <Row style={{height: '10vh', margin: '5px'}}>
          //               <div style={{
          //       backgroundColor: '#a8a9a8',
          //       borderRadius: '4px',
          //       border: '1px solid', marginTop: '1px', marginLeft: '5px', //height: '50px',
          //       paddingLeft: 0, paddingRight: 0,
          //                 // paddingTop: '15px',
          //       fontSize: '14px',
          //       lineHeight: '10vh'}} sm={{span: 10}}
          //       onClick={()=>props.handleClickDoge('btc')}
          //       >BTC</div>
          //           </Row>
          // <Row style={{height: '10vh', margin: '5px'}}>
          //               <div style={{
          //       backgroundColor: '#a8a9a8',
          //       borderRadius: '4px',
          //       border: '1px solid', marginTop: '1px', marginLeft: '5px', //height: '50px',
          //       paddingLeft: 0, paddingRight: 0,
          //                 // paddingTop: '15px',
          //       fontSize: '14px',
          //       lineHeight: '10vh'}} sm={{span: 10}}
          //       onClick={()=>props.handleClickDoge('ltc')}
          //       >LTC</div>
          //           </Row>
    		}

    		<Row style={{height: '10px'}} />

        <NumberPad handleKeyClick={props.handleKeyClick} />

    	</Col>

    	<Col sm={{span: 1}} />

    </Row>
  )
}

export default RightPanel
