import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const Tip = props => {
  return (
    <div style={{backgroundColor: 'black', height: '100vh', color: 'white'}}>
    	<Row style={{height: '8vh'}} />

    	<Row>
        {
          props.storeId == 0 ? (
            <></>
          ) : (
                                           <div style={{color: 'white', fontSize: '36px', textAlign: 'center'}}>Balance Due ${ props.total().toFixed(2) }</div>
          )
        }
    		<div style={{color: 'white', fontSize: '30px', textAlign: 'center'}}>Would you like to leave a tip?</div>
    	</Row>
    	<Row style={{height: '56px'}} />

    	<Row>
    		<Col sm={{span: 1}}/>
    		<Col sms={{span: 10}}>
    			<Row>
            {
              (
                props.storeId == 0 ? [
                  {tipId: 'tip-5-flat', tipText: '$5.00'},
                  {tipId: 'tip-10-flat', tipText: '$10.00'},
                  {tipId: 'tip-20-flat', tipText: '$20.00'},
                ] : [
                  {tipId: 'tip-25', tipText: '25%', tipMath: '$' + (props.total() * 1.25).toFixed(2)},
                  {tipId: 'tip-20', tipText: '20%', tipMath: '$' + (props.total() * 1.20).toFixed(2)},
                  {tipId: 'tip-18', tipText: '18%', tipMath: '$' + (props.total() * 1.18).toFixed(2)},
                ]
              ).map((tip) => (
    				<Col style={{padding: '5px', margin: '0px', fontSize: '36px', textAlign: 'center', heightt: '100px', borderRadius: '4px'}} sm={{span: 4}}>
    					<div style={{backgroundColor: (props.tipSelect == tip.tipId ? '#169EDD' : 'gray')}} onClick={()=>props.handleTip(tip.tipId)}>
    						<Row><Col style={{fontSize: '5vh', textAlign: 'center'}}>{tip.tipText}</Col></Row>
    						<Row><Col style={{fontSize: '21px', textAlign: 'center'}}>{tip.tipMath}</Col></Row>
    					</div>
    				</Col>
                )
              )
            }
    			</Row>
    		</Col>
    		<Col sm={{span: 1}}/>
    	</Row>

    	<Row>
    		<Col sm={{span: 1}} />
    		<Col sms={{span: 10}}>
    			<Row>
            {
              <Col style={{padding: '5px', margin: '0px', fontSize: '21px', heightt: '100px', textAlign: 'center', lineHeightt: '100px'}}>
                <div style={{backgroundColor: (props.tipSelect == 'tip-cust' ? '#169EDD' : 'gray')}} onClick={()=>props.handleTip('tip-cust')}>
                  <Row><Col>Custom tip ${props.customTip ? props.customTip.toFixed(2) : ''}</Col></Row>
                </div>
              </Col>
            }
            {
              props.storeId == 0 ? (
                <></>
              ) : (
    						<Col style={{padding: '5px', margin: '0px', fontSize: '21px', heightt: '100px', textAlign: 'center', lineHeightt: '100px'}}>
    							<div style={{backgroundColor: (props.tipSelect == 'tip-no' ? '#169EDD' : 'gray')}} onClick={()=>props.handleTip('tip-no')}>
    								<Row><Col>No tip</Col></Row>
    							</div>
    						</Col>
              )
            }
    			</Row>
    		</Col>
    		<Col sm={{span: 1}} />
    	</Row>

    	<Row style={{height: '8vh'}} />

    	<Row style={{height: '80px', lineHeight: '50px'}}>
    		<Col sm={{span: 1}} />
    		<Col sm={{span: 10}} style={{textAlign: 'center', height: '50px', backgroundColor: (props.tipSelect ? '#169EDD' : 'gray'), color: 'black'}} onClick={props.handleContinueClick} >
          Continue
        </Col>
    		<Col sm={{span: 1}} />
    	</Row>
    </div>
  )
}

export default Tip
