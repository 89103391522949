import '../App.css'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import React from 'react';
import QRCode from 'qrcode';
import socketIO from "socket.io-client";
import icon from '../images/dogeIcon.png';

export class Pdq extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			items: [{name: 'Brooklyn Slice', color: 'pink', price_cents: 332}], 
			page: 0, 
		};

		this.handleClick = this.handleClick.bind(this);
		this.handleClickPay = this.handleClickPay.bind(this);
		this.handleClickDoge = this.handleClickDoge.bind(this);
		this.handleKeyClick = this.handleKeyClick.bind(this);
		this.handleContinueClick = this.handleContinueClick.bind(this);
		this.setQRCode = this.setQRCode.bind(this);
		this.checkPayment = this.checkPayment.bind(this);
	}

	handleClick(e) {
		this.setState((state, props) => ({
		  items: [...state.items, {name: e.target.innerText, price_cents: parseInt(e.target.dataset.price)}]
		}));
	}

	handleClickPay() {
		this.setState({page: 1});
	}

	handleClickDoge() {
		console.log('clicked doge')
		this.setState({page: 2});
	}

	handleKeyClick(e) {
		
	}

	handleContinueClick() {
		this.setState({page: 3});
		// let priceUrl = 'https://sochain.com//api/v2/get_price/DOGE/USD';
		// fetch(priceUrl)
		// 	.then(res => res.json())
		// 	.then(payload => {
		// 		let price = payload.data.prices[0].price
		// 		let addr = 'DCQF28zA6TKeS9S9z7GPrTbrBB6gaseX5B';
		//
		// 		let walletUrl = process.env.REACT_APP_WALLET_URL;
		//
		// 		walletUrl = walletUrl ? walletUrl : 'doge.b3-well.com/payments/new'
		// 		fetch(`https://${walletUrl}`).then(resaddr => resaddr.text())
		// 			.then(txtaddr => {
		// 				console.log('text:')
		// 				console.log(txtaddr);
		// 				addr = txtaddr;
		//
		// 				let paymentUrl = `dogecoin:${addr.trim()}?amount=${this.total() / price}&message=DogeExpress`;
		// 				console.log(paymentUrl)
		// 				setTimeout(this.checkPayment, 500, addr)
		// 				QRCode.toDataURL(paymentUrl, this.setQRCode);
		// 		})
		//   });

		let price = 0.08; //payload.data.prices[0].price
		let addr = 'DCQF28zA6TKeS9S9z7GPrTbrBB6gaseX5B';

		let walletUrl = process.env.REACT_APP_WALLET_URL;

		walletUrl = walletUrl ? walletUrl : 'doge.b3-well.com/payments/new'
		fetch(`https://${walletUrl}`).then(resaddr => resaddr.text())
			.then(txtaddr => {
				console.log('text:')
				console.log(txtaddr);
				addr = txtaddr;

				let paymentUrl = `dogecoin:${addr.trim()}?amount=${this.total() / price}&message=DogeExpress`;
				console.log(paymentUrl)
				setTimeout(this.checkPayment, 500, addr)
				QRCode.toDataURL(paymentUrl, this.setQRCode);
		})
	}

	checkPayment(paymentAddr) {
		let checkUrl = `https://doge.b3-well.com/payments/${paymentAddr}.json`
		fetch(checkUrl).then(res => res.text())
			.then(txt => {
				console.log('bal rcvd:')
				console.log(txt)
				if (parseInt(txt) > 0) {
					console.log('payment complete!')
					this.setState({rcvd: parseFloat(txt)})
				} else {
					console.log('payment pending...')
					setTimeout(this.checkPayment, 500, paymentAddr)
				}
			})
	}

	setQRCode(err, url) {
		this.setState({qrCodeUrl: url});
		// const socket = socketIO.connect("http://b3-well.com:4567");
		// socket.on("sendSockets", (sockets) => {
		// 	console.log(`sockets: ${sockets}`)
		//     });
	}

	total() {
		return (this.state.items.map((item) => (item.price_cents))).reduce((partialSum, a) => partialSum + a, 0) / 100;
	}

	render() {
		if (this.state.page == 3) {
			return (
				<Container style={{backgroundColor: 'black', height: '800px', color: 'white'}}>
					<Row>
						<Col sm={{span: 5}}>
					 		<Row style={{backgroundColor: '#4189C7', height: '500px', margin: '10px', borderRadius: '4px'}}><Col>
								<Row style={{height: '60px'}} />
								{
									this.state.items.map((item, index) => (
										<Row key={`cart-${index}`}>
											<Col style={{textAlign: 'left'}} sm={{ span: 10 }}><strong>{item.name}</strong></Col>
											<Col style={{textAlign: 'right'}} span={2}>${item.price_cents / 100}</Col>
										</Row>
									))
								}
								<Row>
									<Col style={{textAlign: 'left'}} sm={{ span: 10 }}>- DogeExpress Discount</Col>
									<Col style={{textAlign: 'right'}} span={2}>- $0.33</Col>
								</Row>
							</Col></Row>

					 		<Row style={{backgroundColor: '#4189C7', height: '200px', margin: '10px', borderRadius: '4px'}}>
								<Col>

									<Row>
										<Col style={{textAlign: 'left'}} sm={{ span: 10 }}>Subtotal:</Col>
										<Col style={{textAlign: 'left'}} span={1}>${this.total()}</Col>
									</Row>
								
									<Row>
										<Col style={{textAlign: 'left'}} sm={{ span: 10 }}>Tax:</Col>
										<Col style={{textAlign: 'left'}} span={1}>$0.33</Col>
									</Row>
								
									<Row>
										<Col style={{textAlign: 'left'}} sm={{ span: 10 }}>Tip:</Col>
										<Col style={{textAlign: 'left'}} span={1}>$0.33</Col>
									</Row>
								
									<Row>
										<Col style={{textAlign: 'left'}} sm={{ span: 10 }}>Balance due:</Col>
										<Col style={{textAlign: 'left'}} span={1}>${this.total() + 0.33}</Col>
									</Row>
								
								</Col>
							</Row>
						</Col>

						<Col sm={{span: 7}}>
								<Row style={{height: '120px'}} />

								<Row style={{fontSize: '30px', textAlign: 'center'}}>
									<Col><img src={icon} style={{width: '44px', height: '44px'}} /> DogeExpress payment</Col>
								</Row>

								<Row style={{height: '100px'}} />

								<Row style={{textAlign: 'center'}}>
									<Col>Please scan this code to complete payment</Col>
								</Row>

								<Row style={{height: '50px'}} />

								<Row style={{textAlign: 'center'}}>
									<Col>
										<img src={this.state.qrCodeUrl} />
									</Col>
								</Row>

								<Row style={{height: '50px'}} />

								{
									this.state.rcvd ? (
										<Row style={{textAlign: 'center'}}>
											<Col sm={{span: 2}} />
											<Col>Payment Received! D${this.state.rcvd}</Col>
											<Col sm={{span: 2}} />
											</Row>
										) : (
											<Row style={{textAlign: 'center'}}>
												<Col sm={{span: 2}} />
												<Col>Send only Dogecoin (DOGE) to this address. Sending any other coins may result in permanent loss.</Col>
												<Col sm={{span: 2}} />
											</Row>
										)
								}
						</Col>
					</Row>
				</Container>
			);
		}

		if (this.state.page == 2) {
			return (
				<Container style={{backgroundColor: 'black', height: '800px', color: 'white'}}>
					<Row style={{height: '120px'}} />

					<Row>
						<div style={{color: 'white', fontSize: '36px', textAlign: 'center'}}>Balance Due ${this.total()}</div>
						<div style={{color: 'white', fontSize: '30px', textAlign: 'center'}}>Would you like to leave a tip?</div>
					</Row>
					<Row style={{height: '56px'}} />

					<Row>
						<Col sm={{span: 1}}/>
						<Col sms={{span: 10}}>
							<Row style={{flexWrap: 'nowrap'}}>
								<Col style={{padding: '5px', margin: '0px', fontSize: '36px', textAlign: 'center', height: '100px', borderRadius: '4px'}} sm={{span: 4}}>
									<div style={{backgroundColor: 'gray'}}>
										<Row><Col style={{fontSize: '36px', textAlign: 'center'}}>25%</Col></Row>
										<Row><Col style={{fontSize: '21px', textAlign: 'center'}}>${(this.total() * 1.25).toFixed(2)}</Col></Row>
									</div>
								</Col>
								<Col style={{padding: '5px', margin: '0px', fontSize: '36px', textAlign: 'center', height: '100px', borderRadius: '4px'}} sm={{span: 4}}>
									<div style={{backgroundColor: 'gray'}}>
										<Row><Col style={{fontSize: '36px', textAlign: 'center'}}>20%</Col></Row>
										<Row><Col style={{fontSize: '21px', textAlign: 'center'}}>${(this.total() * 1.20).toFixed(2)}</Col></Row>
									</div>
								</Col>
								<Col style={{padding: '5px', margin: '0px', fontSize: '36px', textAlign: 'center', height: '100px', borderRadius: '4px'}} sm={{span: 4}}>
									<div style={{backgroundColor: 'gray'}}>
										<Row><Col style={{fontSize: '36px', textAlign: 'center'}}>18%</Col></Row>
										<Row><Col style={{fontSize: '21px', textAlign: 'center'}}>${(this.total() * 1.18).toFixed(2)}</Col></Row>
									</div>
								</Col>
							</Row>
						</Col>
						<Col sm={{span: 1}}/>
					</Row>

					<Row>
						<Col sm={{span: 1}} />
						<Col sms={{span: 10}}>
							<Row>
								<Col style={{padding: '5px', margin: '0px', fontSize: '21px', height: '100px', textAlign: 'center', lineHeight: '100px'}}>
									<div style={{backgroundColor: 'gray'}}>
										<Row><Col>Custom tip</Col></Row>
									</div>
								</Col>
								<Col style={{padding: '5px', margin: '0px', fontSize: '21px', height: '100px', textAlign: 'center', lineHeight: '100px'}}>
									<div style={{backgroundColor: 'gray'}}>
										<Row><Col>No tip</Col></Row>
									</div>
								</Col>
							</Row>
						</Col>
						<Col sm={{span: 1}} />
					</Row>

					<Row style={{height: '200px'}} />

					<Row style={{height: '50px', lineHeight: '50px'}}>
						<Col sm={{span: 1}} />
						<Col sm={{span: 10}} style={{textAlign: 'center', backgroundColor: '#169EDD', color: 'black'}} onClick={this.handleContinueClick} >Continue</Col>
						<Col sm={{span: 1}} />
					</Row>
				</Container>
			);
		}

		return (
		  <div className="App">
				<Container>

					<Row style={{backgroundColor: 'black', columnGap: 0}}>
						<Col sm={{span: 12}}>
							<Row>
								<Col sm={{span: 2}}>
									<div style={{backgroundColor: '#B72C2C', height: '50px', borderRadius: '0px 0px 10px 10px', color: 'white'}}>
										Cancel
									</div>
								</Col>
								<Col sm={{span: 9}} />
								<Col sm={{span: 1}}>
									<div style={{backgroundColor: '#B72C2C', height: '50px', borderRadius: '0px 0px 10px 10px', color: 'white'}}>
										Gift Card
									</div>
								</Col>
							</Row>
						</Col>
					</Row>

					<Row style={{backgroundColor: 'black', columnGap: 0, height: '10px'}} />

					<Row style={{columnGap: 0, backgroundColor: 'black', height: '500px'}}>
						<Col style={{backgroundColor: '#afafaf'}} sm={{span: 4}}>
							<Row style={{background: 'transparent linear-gradient(0deg, #000000 0%, #545454 100%) 0% 0% no-repeat padding-box', color: 'white'}}>
								<Col>
									#
								</Col>
								<Col>
									Item Description
								</Col>
								<Col>
									Price
								</Col>
							</Row>

							<Row style={{height: '350px'}} >
								<Col>
									{
										this.state.items.map((label, index) => (
											<Row>
												<Col style={{textAlign: 'left'}}>{`${label.name}`}</Col>
												<Col style={{textAlign: 'right'}}>${label.price_cents/100}</Col>
											</Row>
										))
									}
								</Col>
							</Row>

							<Row style={{color: 'yellow', textAlign: 'left', background: 'transparent linear-gradient(180deg, #000000 0%, #545454 100%) 0% 0% no-repeat padding-box', height: '126px'}}>
								<Col>
									<Row>
			<Col>
			Sub Total:
			</Col>
			<Col style={{textAlign: 'right'}}>${this.total()}</Col>
									</Row>
									<Row>
			<Col>
			Tax:
			</Col>
			<Col style={{textAlign: 'right'}}>$0.00</Col>
									</Row>
									<Row>
			<Col>
			Delivery Fee:
			</Col>
			<Col style={{textAlign: 'right'}}>$0.00</Col>
									</Row>

									<Row style={{color: 'white', fontSize: '24px'}}><div style={{textAlign: 'center'}}>Total ${this.total()}</div></Row>
								</Col>

							</Row>

						</Col>

						<Col>
							<Row style={{height: '60px'}}>
							{
								[
									{label: 'Little John Combo', price_cents: 101},
									{label: 'Slim Combo', price_cents: 101},
									{label: 'Original Combo', price_cents: 101},
									{label: 'Favorite Combo', price_cents: 101},
									{label: 'Gargantuan Combo', price_cents: 101},
									{label: 'LTO Combo', price_cents: 101},
									{label: 'Item 7', price_cents: 101},
								].map((item, index) => (
									<Col onClick={this.handleClick} style={{color: 'white', backgroundColor: '#555555', margin: '2px', borderRadius: '10px', fontSize: '12px'}} key={`btn-${index}`}
									data-price={item.price_cents}>{item.label}</Col>
								))
							}
							</Row>

							{
								[
									['#F62929', '#F62929', '#F62929', '#A56525', '#A4A4A4', '#A4A4A4', '#A4A4A4'],
									['#F62929', '#F62929', '#F62929', '#A56525', '#A4A4A4', '#A4A4A4', '#A4A4A4'],
									['#F62929', '#F62929', '#F62929', '#EFEF22', '#A4A4A4', '#A4A4A4', '#A4A4A4'],
									['#F62929', '#F62929', '#F62929', '#EFEF22', '#A4A4A4', '#A4A4A4', '#A4A4A4'],
									['#F62929', '#F62929', '#F62929', '#29A929', '#D2D2D2', '#D2D2D2', '#D2D2D2'],
									['#F62929', '#F62929', '#F62929', '#29A929', '#82AADE', '#82AADE', '#82AADE'],
									['#29A929', '#29A929', '#29A929', '#D2D2D2', '#6B5E7B', '#6B5E7B', '#6B5E7B'],
								].map((row, index) => (
									<Row style={{height: '60px'}}>
									{
										row.map((label, index) => (
											<Col onClick={this.handleClick} style={{color: 'black', backgroundColor: label, margin: '2px', borderRadius: '10px', fontSize: '12px'}} key={`btn-${index}`}
											data-price={102}>{`Item ${index}`}</Col>
										))
									}
									</Row>
								))
							}
						</Col>

						<Col sm={{span: 1}} style={{marginRight: '5px'}}>
							<Row style={{backgroundColor: '#B72C2C', borderRadius: '10px', marginBottom: '5px', alignContent: 'center', height: '55px', color: 'white', marginTop: '2px'}}> Special Instructions</Row>
							<Row style={{backgroundColor: '#29A929', borderRadius: '10px', marginBottom: '5px', alignContent: 'center', height: '115px', color: 'white'}}><Col>Send</Col></Row>
							<Row style={{backgroundColor: '#D2D2D2', borderRadius: '10px', marginBottom: '5px', alignContent: 'center', height: '55px', color: ''}}>      <Col>Pay</Col></Row>
							<Row style={{backgroundColor: '#D2D2D2', borderRadius: '10px', marginBottom: '5px', alignContent: 'center', height: '55px', color: ''}}>      <Col>Rewards Lookup</Col></Row>
							<Row style={{backgroundColor: '#D2D2D2', borderRadius: '10px', marginBottom: '5px', alignContent: 'center', height: '55px', color: ''}} onClick={this.handleClickDoge} >      <Col>DogeExpress</Col></Row>
							<Row style={{backgroundColor: '#D2D2D2', borderRadius: '10px', marginBottom: '5px', alignContent: 'center', height: '55px', color: ''}}>      <Col>Charge Card</Col></Row>
							<Row style={{backgroundColor: '#D2D2D2', borderRadius: '10px', marginBottom: '5px', alignContent: 'center', height: '55px', color: ''}}>      <Col>OOPS</Col></Row>
						</Col>

					</Row>
				</Container>
			</div>
		);
	}
}

export default Pdq;
