import logo from '../images/de.png'
import mydoge from '../images/mydoge.webp'
import coinbase from '../images/coinbasewallet.png'
import exodus from '../images/exodus.png'
import trust from '../images/trustwallet.png'
import safepal from '../images/safepal.png'
import atomic from '../images/atomic.jpeg'

const SupportedWallets = props => {
  return (
          <center style={{position: 'fixed', bottom: 0, left: '50%', transform: 'translate(-50%,0)', marginBottom: '5vh', background: 'white', borderRadius: '30px', color: 'black', padding: '20px', width: '95vw'}}>
            <div style={{textAlign: 'left', marginLeft: '7vw'}}>Supported Wallets</div>
            <div className={'orient'} style={{widthh: '70vw'}}>
              <img src={mydoge}   style={{width: '150px'}}/>
              <img src={coinbase} style={{width: '150px', border: '1px solid #1352f4', padding: '10px', marginLeft: '15px'}}/>
              <img src={exodus}   style={{width: '150px'}}/>
              <img src={trust}    style={{width: '150px'}}/>
              <img src={atomic}   style={{width: '110px'}}/>
              <img src={safepal}  style={{width: '150px'}}/>
            </div>
            <div onClick={props.handleClickSplash} style={{cursor: 'pointer', display: 'flex', justifyContent: 'center'}}>
              <div style={{fontSize: '34px'}}>Powered by <img src={logo} style={{width: '270px'}}/></div>
            </div>
          </center>
  )
}

export default SupportedWallets
